
export const ROUTER_NAMES = {
    RELATORIO_LOG_COMUNICACAO: 'RelatorioLogComunicacao',
    RELATORIO_CONTRATO: 'RelatorioContrato',
}

export default [
    {
        name: ROUTER_NAMES.RELATORIO_LOG_COMUNICACAO,
        path: '/relatorios/logComunicacao',
        meta: {
            title: 'Log de Comunicação'
        },
        component: () => import('@/views/Relatorios/RelatorioLogComunicacao')
    },
    {
        name: ROUTER_NAMES.RELATORIO_CONTRATO,
        path: '/relatorios/contrato',
        meta: {
            title: 'Contrato'
        },
        component: () => import('@/views/Relatorios/RelatorioContrato')
    },    
]