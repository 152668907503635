import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
//funcionalidades
import relatorios from './modulos/relatorios';
import alterarSenha from "./modulos/alterarSenha";
import parametros from "./modulos/parametros";
import funcionarios from "./modulos/funcionarios";
import mensagens from "./modulos/mensagens";
import painel from "./modulos/painel";
import orgao from "./modulos/orgao";
import grupoTipoModificacao from "./modulos/grupoTipoModificacao";
import modalidade from "./modulos/modalidade";
import tipoContrato from "./modulos/tipoContrato";
import unidade from './modulos/unidade';
import tipoModificacao from './modulos/tipoModificacao';
import contratado from './modulos/contratado';
import contrato from './modulos/contrato';
import modificacao from './modulos/modificacao';
import pagamento from './modulos/pagamento';
import itemContrato from './modulos/itemContrato';

Vue.use(VueRouter)

const routes = [
    ...relatorios,
    ...alterarSenha,
    ...parametros,
    ...funcionarios,
    ...mensagens,
    ...painel,
    ...orgao,
    ...grupoTipoModificacao,
    ...modalidade,
    ...tipoContrato,
    ...unidade,
    ...tipoModificacao,
    ...contratado,
    ...contrato,
    ...modificacao,
    ...pagamento,
    ...itemContrato,
    {
        name: 'Unauthorized',
        path: '/unauthorized',
        meta: {
            title: 'Sem Autorização',
            unauthorized: true,
        },
        component: () => import('@/views/Unauthorized')
    },
    {
        name: 'LoginUsuario',
        path: '/login',
        props: {
            type: 'usuario'
        },
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Login')
    },
    {
        name: 'TelaAberta',
        path: '/tela-teste-aberta',
        props: {
            titulo: "Tela Aberta"
        },        
        meta: {
            loginPage: true
        },
        component: () => import('@/views/TesteResponsivo/TelaAberta')
    },      
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    await store.restored;
    const {meta} = to;

    function isGoingToLoginPage() {
        return meta.hasOwnProperty('loginPage') && meta.loginPage;
    }

    function isGoingToUnauthorizedPage() {
        return meta.hasOwnProperty('unauthorized') && meta.unauthorized;
    }

    async function handleIsAuthenticated() {
        const defaultHomePage = await store.dispatch('auth/getDefaultHomePage');
        if (isGoingToLoginPage()) {
            next({
                name: defaultHomePage
            });
        } else {
            const authorizedLinks = [...store.getters['auth/authorizedLinks']];
            if (isGoingToUnauthorizedPage()) {
                next();
            } else {
                if (authorizedLinks.indexOf(to.path) > -1) {
                    next();
                } else {
                    if (to.path === '/') {
                        next({
                            name: defaultHomePage
                        })
                    } else {
                        next('/unauthorized')
                    }
                }
            }
        }
    }

    async function handleNotAuthenticated() {
        if (isGoingToLoginPage()) {
            next();
        } else {
            const defaultLoginPage = await store.dispatch('auth/getDefaultLoginPage');
            next({
                name: defaultLoginPage
            })
        }
    }

    if (store.getters['auth/isAuthenticated']) {
        await handleIsAuthenticated();
    } else {
        await handleNotAuthenticated();
    }

})

export default router
